a,
a.read-more,
.btn-dropdown {
  color: $site-link-color;

  &:hover,
  &:focus {
    color: $site-link-color-hover;
  }
}

#main-content-section .it-page-section .richtext-blocks .full-width {
  width: 100% !important;
  margin: 0 -24px !important;
  left: 0;
}
.scroll-to-top {
  .btn {
    top: 2rem;
    bottom: unset;

    //small - tablet
    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      top: 2rem;
      bottom: unset;
    }
  }
}
