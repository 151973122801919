.it-header-slim-wrapper {
  background-color: darken($secondary, 5);
}

.it-header-center-wrapper {
  font-weight: 400;
  background-color: $secondary;

  .it-header-center-content-wrapper {
    .it-brand-wrapper {
      a {
        h3 {
          font-size: 1.3em;
        }
      }
    }
  }
}
